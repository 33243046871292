/* You can add global styles to this file, and also import other style files */
// @import "./assets/sass/style";
// @import "~@angular/material/theming";
// @include mat-core();

// @import "~bootstrap/dist/css/bootstrap.css";
// @import "~bootstrap/scss/bootstrap";
// @import "~bootstrap-icons/font/bootstrap-icons";
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('./assets/fonts/OpenSansSemiBold.eot');
  src: url('./assets/fonts/OpenSansSemiBold.eot') format('embedded-opentype'),
    url('./assets/fonts/OpenSansSemiBold.woff2') format('woff2'),
    url('./assets/fonts/OpenSansSemiBold.woff') format('woff'),
    url('./assets/fonts/OpenSansSemiBold.ttf') format('truetype'),
    url('./assets/fonts/OpenSansSemiBold.svg#OpenSansSemiBold') format('svg');
}

@font-face {
  font-family: 'OpenSansExtraBold';
  src: url('./assets/fonts/OpenSansExtraBold.eot');
  src: url('./assets/fonts/OpenSansExtraBold.eot') format('embedded-opentype'),
    url('./assets/fonts/OpenSansExtraBold.woff2') format('woff2'),
    url('./assets/fonts/OpenSansExtraBold.woff') format('woff'),
    url('./assets/fonts/OpenSansExtraBold.ttf') format('truetype'),
    url('./assets/fonts/OpenSansExtraBold.svg#OpenSansExtraBold') format('svg');
}


@font-face {
  font-family: 'OpenSansRegular';
  src: url('./assets/fonts/OpenSansRegular.eot');
  src: url('./assets/fonts/OpenSansRegular.eot') format('embedded-opentype'),
    url('./assets/fonts/OpenSansRegular.woff2') format('woff2'),
    url('./assets/fonts/OpenSansRegular.woff') format('woff'),
    url('./assets/fonts/OpenSansRegular.ttf') format('truetype'),
    url('./assets/fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
}


@font-face {
  font-family: 'OpenSansLight';
  src: url('./assets/fonts/OpenSansLight.eot');
  src: url('./assets/fonts/OpenSansLight.eot') format('embedded-opentype'),
    url('./assets/fonts/OpenSansLight.woff2') format('woff2'),
    url('./assets/fonts/OpenSansLight.woff') format('woff'),
    url('./assets/fonts/OpenSansLight.ttf') format('truetype'),
    url('./assets/fonts/OpenSansLight.svg#OpenSansLight') format('svg');
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('./assets/fonts/OpenSansBold.eot');
  src: url('./assets/fonts/OpenSansBold.eot') format('embedded-opentype'),
    url('./assets/fonts/OpenSansBold.woff2') format('woff2'),
    url('./assets/fonts/OpenSansBold.woff') format('woff'),
    url('./assets/fonts/OpenSansBold.ttf') format('truetype'),
    url('./assets/fonts/OpenSansBold.svg#OpenSansBold') format('svg');
}


body {
  color: #000;
  font-family: 'OpenSansRegular';
}

// .media { display: -ms-flexbox; display: flex; -ms-flex-align: start; align-items: flex-start;}
img {
  max-width: 100%;
}

a {
  display: inline-block;
  text-decoration: none !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
}

.btn {
  border-radius: 20px !important;
  font-size: 15px;
  font-family: 'OpenSansSemiBold';
  padding: 7px 22px;
  text-transform: uppercase;
}

.white-btn {
  border: 2px solid #fff;
  color: #fff;
}

.white-btn:hover,
.white-btn:focus,
.white-btn:active {
  background-color: #fff;
  color: #00a8a8;
}

.blue-btn {
  border: 2px solid #4aa0d9 !important;
  color: #fff !important;
  background-color: #4aa0d9 !important;
}

.blue-btn:hover,
.blue-btn:focus,
.blue-btn:active {
  color: #4aa0d9;
  background-color: transparent;
  border: 2px solid #4aa0d9;
}

.green-btn {
  background-color: #57a4d5;
  border: 2px solid #00a8a8;
  color: #fff;
}

.green-btn:hover,
.green-btn:focus,
.green-btn:active {
  background-color: transparent;
  color: #00a8a8;
}

.top-header {
  border-bottom: 1px solid #f2f2f2;
  padding:10px 0px 10px 0px;
}

.login-listng {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.login-listng li a {
  border: 2px solid #000000;
  border-radius: 20px;
  font-family: 'OpenSansSemiBold';
  font-size: 15px;
  padding: 4px 16px 6px 16px;
}
.login-listng li a img{
  width: 13px;
    height: 13px;
    position: relative;
    top: -2px;
    margin-right: 3px;
}

.login-listng li a.btn:hover {
  border: 2px solid #4aa0d9;
  border-radius: 20px;
  font-family: 'OpenSansSemiBold';
  font-size: 15px;
  color: #4aa0d9;
}

.login-listng li:not(:last-child) {
  margin-right: 15px;
}

.navbar-brand {
  max-width: 196px;
  margin-right: 0;
}


.common-bg {
  background-size: cover;
  position: relative;
}

.bg-overlay {
  position: relative;
}

.bg-overlay::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: none;
}

.bottom-header .navbar-nav .nav-link {
  padding: 25px 0;
}

.bottom-header .navbar .dropdown-menu {
  margin: 0;
  min-width: 250px;
  background-color: #f2f2f2;
  border-radius: 0 !important;
  left: 0px;
  padding: 15px 0px 20px;
}
.bottom-header .navbar .dropdown-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f2f2f2;
  position: absolute;
  top: -15px;
  left: 35px;
}
.bottom-header .navbar .dropdown-menu.dropdown-submenu::before{
  display: none !important;
}
.bottom-header .navbar .dropdown-menu a {
  font-size: 15px;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}
.bottom-header .navbar .dropdown-menu a .ri-arrow-right-s-line{
  font-size: 15px;
}

.heading-one {
  line-height: 1 !important;
  font-family: 'OpenSansExtraBold';
  font-size: 50px;
}

.banner-content {
  position: relative;
  z-index: 1;
  color: #fff;
  padding-left: 60px;
  width: 100%;
  padding-right: 30px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-slider {
  width: 100%;
  padding: 0px 0 0px;
  height: 100%;
}

.banner-slider .heading-one {
  margin-bottom: 25px;
  width: 100%;
}

.banner-slider p {
  margin-bottom: 30px;
  font-size: 21px;
  width: 60%;
}

.navbar-nav {
  font-size: 15px;
}

.banner {
  position: relative;
}

.banner .owl-dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.banner .owl-dots span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.banner .owl-dot.active span {
  background-color: #fff;
}

.banner .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  width: 100%;
}

.banner .owl-nav button span {
  display: none;
}

.banner .owl-nav .owl-next {
  right: -15px;
  background-image: url("./assets/images/right-arrow.svg") !important;
  width: 16px;
  height: 30px;
  float: right;
  background-size: 16px !important;
  background-repeat: no-repeat !important;
  margin-right: 10px !important;
}

.banner .owl-nav .owl-prev {
  left: -15px;
  background-image: url("./assets/images/left-arrow.svg") !important;
  width: 16px;
  height: 30px;
  float: left;
  background-size: 16px !important;
  background-repeat: no-repeat !important;
  margin-left: 10px !important;
}

.banner-btn {
  margin-bottom: 20px;
}

.owl-theme .owl-nav [class*=owl-] {
  background-color: transparent !important;
}

.top-header .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-listng li:last-child a {
  background-color: #4aa0d9;
  color: #fff;
  border: 2px solid #4aa0d9;
}

.login-listng li:last-child a:hover {
  background-color: #fff;
  color: #000;
  border: 2px solid #4aa0d9;
}

.login-listng li:last-child a:hover img {
  filter: invert(1);
}

.top-menu-nav>li {
  position: relative;
  margin-right: 25px;
}

.top-menu-nav>li:not(:last-child)::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  border-right: 1px solid #e6e6e6;
  right: -14px;
}

.top-menu-nav .navbar-toggler {
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.events-bg-main{
  background:#4aa0d90d;
  padding: 30px 30px;
  border-radius: 10px;
}
.top-menu-nav .navbar-collapse {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
  height: 100vh !important;
  background: #f2f2f2;
  z-index: 999;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.top-menu-nav .navbar-collapse.show {
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.close-menu {
  display: none;
  margin-top: 30px;
  padding: 0 15px;
  cursor: pointer;
}

.top-menu-nav .navbar-collapse.show .close-menu {
  display: block;
}

.top-menu-nav .navbar-nav {
  padding: 30px 0px;
}

.top-menu-nav .navbar-nav li.dropdown>a::after {
  display: none;
}

.top-menu-nav .navbar-nav .dropdown-menu {
  display: block;
  padding: 0;
  background: transparent;
  font-size: 15px;
  font-family: 'OpenSansSemiBold';
  margin-bottom: 15px;
}

.top-menu-nav .navbar-nav .nav-link {
  font-size: 20px;
  font-family: 'OpenSansSemiBold';
  text-transform: uppercase;
}

.top-menu-nav .navbar-nav li.active>a {
  color: #000 !important;
}

.top-menu-nav .navbar-nav .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
}

.top-menu-nav .navbar-nav .dropdown-menu.sub-menu {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}

.top-menu-nav .navbar-nav .dropdown-menu.sub-menu {
  font-size: 13px;
  font-family: 'OpenSansRegular';
}


.heading-six {
  font-size: 15px;
  font-family: 'OpenSansSemiBold';
  margin-bottom: 0;
  text-transform: uppercase;
}

.news-cover {
  background-color: #b9cefd;
  padding-left: 20px;
  padding-right: 20px;
}

.news-wrap {
  padding-top: 50px;
}

.news-wrap .heading-six {
  background-color: #00a8a8;
  display: inline-block;
  color: #fff;
  padding: 5px 15px 5px 5px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.news-wrap .slhead {
  background-color: #024272;
}

.common-top-head {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 40px;
}

.view-more {
  font-size: 15px;
  font-family: 'OpenSansSemiBold';
  color: #1566b1;
  text-transform: uppercase;
}

.view-more:hover {
  background-color: #57a4d5;
  color: #fff;
  border-radius: 15px;
  padding: 2px 10px;
  text-decoration: none;
}

.common-top-head .view-more {
  margin-left: auto;
  padding: 2px 10px;
}

.heading-four {
  font-size: 22px;
  margin-bottom: 25px;
  font-family: 'OpenSansExtraBold';
}

.article-info {
  margin-bottom: 0;
  font-size: 12px;
  font-family: 'OpenSansSemiBold';
  text-transform: capitalize;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.article-info span {
  color: #fff;
  font-family: 'OpenSansSemiBold';
  background-color: #57a4d5;
  border-radius: 10px;
  padding: 3px 15px;
  text-transform: uppercase;
}
.billing_information_and_shipping_information{
  margin-left: 30px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px #7878788c;
  padding: 12px 20px;
  border-radius: 5px;
}
.billing_information_and_shipping_information h6{
  text-align: center;
  font-weight: 700;
  font-size: 19px;
}
.billing_information_and_shipping_information ul{
  margin-top: 20px;
}
.billing_information_and_shipping_information ul li{
  display: block !important;
  font-size: 16px !important;
}
.billing_information_and_shipping_information ul li span{
  padding-right: 10px;
}
.article-info li {
  position: relative;
}

.article-info li:not(:last-child) {
  margin-right: 13px;
}

// .article-info li:not(:last-child)::after {
//   position: absolute;
//   content: "";
//   top: 50%;
//   transform: translateY(-50%);
//   height: 80%;
//   bottom: 0;
//   right: -7px;
//   border-right: 1px solid #000;
// }
.article-detail .product-gallery__featured .owl-carousel .owl-item img{
  height: 300px;
  object-fit: cover;
  background-color: rgba(204, 204, 204, 0.231372549);
}
.article-detail .product-gallery__carousel .owl-carousel .owl-item img{
  height: 150px;
  object-fit: cover;
  background-color: rgba(204, 204, 204, 0.231372549);
}
.media-img {
  margin-right: 15px;
  width: 85px;
  height: 85px;
}
.about-us-main-one ul{
  padding: 0px 30px;
}
.media-img img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  min-width: 100px;
  object-fit: cover;
  background-color: rgba(204, 204, 204, 0.231372549);
}

.news-info {
  font-size: 13px;
  font-family: 'OpenSansSemiBold';
  margin-bottom: 5px;
  line-height: 1.3;
  margin-top: 10px;
}

.published {
  display: flex;
  font-size: 12px;
  font-family: "OpenSansLight";
  line-height: 1.3;
  margin-bottom: 0.5px;
  color: #000;
  margin-top: 5px;
}
.published span{
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  min-width: 10px;
  max-width: 110px;
}
.videos .published span{
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  min-width: 10px;
  max-width: 90px;
}
.language-dropdown .btn:first-child:active{
  border:none !important;
  border-color: transparent !important;
}
.read-more {
  font-size: 12px;
  font-family: 'OpenSansSemiBold';
  text-transform: capitalize;
  line-height: 1.3;
  color: #1566b1;
}

.news-cover .news-media>ul>li {
  padding-bottom: 15px;
  padding-top: 0px;
  border-bottom: 1px solid #8cc4e7;
}

.news-cover .news-media>ul>li:last-child {
  border-bottom: none;
}

.news-cover {
  padding-bottom: 20px;
  border-radius: 10px;
}

.news-media>ul>li {
  margin-bottom: 15px;
}

.news-media>ul>li:last-child {
  margin-bottom: 0;
}

.news-bottom-article .news-media ul li {
  margin-bottom: 0px;
}

.latest-news-box {
  position: relative;
  margin-bottom: 20px;
}

.latest-news-img-content {
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.6) 45%, rgba(0, 0, 0, 0.4) 65%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0.07) 100%);
}

.latest-news-img-content .article-info span {
  color: #fff;
}

.latest-news-img-content .article-info li:not(:last-child)::after {
  border-color: #fff;
}

.latest-news-img-content .published {
  color: #fff;
}

.latest-news-img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  min-height: 220px;
  max-height: 220px;
  background-color: rgba(204, 204, 204, 0.231372549);
}

.biomedical {
  padding-bottom: 0px !important;
}

.biomedical .news-bottom-article .news-media {
  background-color: #f4f4f4;
  box-shadow: 2px 3px 5px #ccc;
  padding: 20px;
}

.news-cover .heading-four {
  color: #014373
}

.news-cover .article-info span {
  color: #fff;
  font-family: 'OpenSansSemiBold';
  background-color: #024272;
  border-radius: 10px;
  padding: 3px 15px;
  text-transform: uppercase;
}

.news-cover .published {
  display: flex;
  font-size: 12px;
  font-family: 'OpenSansLight';
  line-height: 1.3;
  margin-bottom: 0.5px;
  color: #01416f;
}

.news-media>ul {
  margin-bottom: 0;
}

.latest-news.opinion-sec {
  padding: 50px 0 50px;
}

.heading-four {
  font-size: 32px;
  margin-bottom: 0px;
}

.topic-top-info p {
  font-size: 20px;
}

.ambassador-content {
  padding-left: 25px;
  padding-right: 25px;
}

// .latest-news {
//   padding: 0px 0;
// }

// .news-bottom-article {
//   margin-bottom: 50px;
// }

.community {
  background: url(./assets/images/community_bg.jpg);
  background-size: cover;
  padding: 20px 20px 20px 20px;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 0 0 1000px rgb(25 96 170 / 60%);
  height: 270px;
  border-radius: 10px;
}

.community-wrap p {
  color: #fff;
  text-align: center;
  font-size: 12px;
  padding-left: 40px;
  padding-right: 40px;
}

.community-wrap h2 {
  margin-bottom: 15px;
  font-size: 32px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 40px;
}

.community .btn {
  margin-top: 20px;
  background-color: #fff;
  color: #214e61;
  border-radius: 25px;
  padding: 8px 25px;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.community .btn:hover {
  background-color: #4aa0d9;
  color: #fff
}

.community .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  width: 100%;
  height: 42px;
}

.community .owl-nav button span {
  display: none;
}

.community .owl-nav .owl-next {
  right: -15px;
  background-image: url("./assets/images/right-arrow.svg") !important;
  width: 21px;
  height: 42px;
  float: right;
}

.community .owl-nav .owl-prev {
  left: -15px;
  background-image: url("./assets/images/left-arrow.svg") !important;
  width: 21px;
  height: 42px;
  float: left;
}


.reviews {
  padding: 45px 0 30px;
}

.review-content {
  padding: 15px;
  background-color: #fafafa;
}

.review-img img {
  width: 100%;
}

.review-main-box {
  margin-bottom: 0;
}

.review-box {
  box-shadow: 0px 0px 10px 0px #ccc;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
.box-full-access{
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #78787869;
  padding: 30px 50px;
  width: 50%;
  border-radius: 10px;
  margin-right: 15px;
  margin-left: 15px;
}
.box-full-access h4{
  color: #333;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 32px;
  margin-bottom: 20px;
}
.box-full-access h5{
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.box-full-access h6{
  color: #333;
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  margin-top: 15px;
  margin-bottom: 15px;
}
.review-box .review-info span {
  color: #fff;
  font-family: 'OpenSansSemiBold';
  background-color: #713a7d;
  font-size: 12px;
  border-radius: 10px;
  padding: 3px 15px;
  text-transform: uppercase;
}

.review-main-box>li {
  margin-bottom: 40px;
}

.now-tranding {
  background: url(./assets/images/trending_bg.jpg);
  background-size: cover;
  padding: 50px 30px 50px;
  margin-top: 40px;
  border-radius: 10px;
}
.comment-box-main{
  box-shadow: 0px 0px 7px 0px #78787861;
  padding: 20px;
  border-radius: 10px;
}
.comment-box-main h3{
  font-size: 20px;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
.comment-box-main textarea{
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 10px;
  padding-top: 10px;
  color: #787878;
}
.my-interaction-main-table input[type="checkbox"]{
  width: 17px;
  height: 17px;
  position: relative;
  top: 3px;
}
.my-interaction-main-table label{
  margin-left: 10px;
}
.comment-message-box{
  margin-top: 40px;
}
.comment-message-box img{
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin-right: 15px;
}
.comment-message-box h5{
  font-size: 20px;
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
}
.comment-message-box h5 span{
  color: #333;
  font-weight: 300;
}
.comment-message-box h6{
  color: #333;
  font-weight: 300;
  font-size: 16px;
  
}
.applyform-input-number .iti{
  width: 100%;
}
.comment-message-box h4{
  color: #3975b7;
  font-size: 15px;
  margin-bottom: 25px;
}
.comment-message-box h4 a{
  color: #3975b7;
  font-size: 15px;
  font-weight: 500;
  text-transform: lowercase;
}
.comment-message-box h3{
  color: #3975b7;
  font-size: 15px;
  font-weight: 500;
}
.comment-message-box h3 a{
  color: #3975b7;
  font-weight: 600;
}
.now-tranding .common-top-head .heading-four {
  color: #fff;
}

.now-tranding .common-top-head .view-more {
  color: #fff;
}

.tranding-box {
  height: 100%;
}

.tranding-box-content {
  background: #fff;
  padding: 15px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
}

.tranding-box-content .buy-now {
  margin-top: auto;
}

.tranding-box-img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.now-tranding-wrap>ul {
  margin-bottom: 0;
}

// .now-tranding-wrap>ul>li {
//   margin-bottom: 30px;
// }

.common-topics .media-img {
  width: 100px;
  height: 100px;
}

.media-img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.common-topic-box {
  position: relative;
  color: #fff;
}

.common-topic-top-img {
  height: 428px;
}

.common-topic-top-img img {
  width: 100%;
  height: 100%;
}

.common-topic-content {
  position: absolute;
  left: -1px;
  width: 100%;
  max-width: 65%;
  padding: 15px;
  background: #00000057;
  border: 1px solid #fff;
  bottom: -1px;
}

.common-topic-content .article-info {
  margin-bottom: 8px;
}

.common-topic-content .news-info {
  font-size: 14px;
  font-family: 'OpenSansLight';
}

.common-topic-content .published {
  margin-bottom: 5px;
  color: #fff;
}

.common-topic-content .topic-top-info p a {
  color: #fff;
}

.common-topic-content .topic-top-info p {
  font-size: 16px;
  font-family: 'OpenSansSemiBold';
  margin-bottom: 8px;
}

.common-topic-content .read-more {
  color: #fff;
}
.header-main-div{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.common-topics .news-media>ul>li {
  padding-bottom: 15px;
  padding-top: 0px;
  border-bottom: 1px solid #8cc4e7;
}

// .common-topics {
//   border-bottom: 1px solid #8cc4e7;
// }

.common-topics .news-media>ul>li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.common-topics {
  padding: 40px 0 50px;
}

.research-analysis {
  padding: 45px 40px 55px;
  // border-bottom: 1px solid #8cc4e7;
  background: #cccccc40;
  margin-top: 40px;
  border-radius: 10px;
}

.research-analysis-content .font-14 {
  line-height: 1.7;
  margin-bottom: 20px;
}

.research-analysis-content .topic-top-info p {
  margin-bottom: 10px;
  font-family: 'OpenSansSemiBold';
}

.research-analysis-content .published {
  margin-bottom: 20px;
}

.research-analysis-content .article-info {
  margin-bottom: 15px;
}

.research-analysis-content-col .research-analysis-content {
  background: #dbd9da;
  height: 100%;
}

.research-analysis-img {
  height: 100%;
}

.research-analysis-img img {
  width: 100%;
  height: 100%;
  min-height: 332px;
  object-fit: cover;
  object-position: center;
  max-height: 360px;
}

@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
  }
}
.readers_write .write-us-wrap{
  padding-right: 70px;
    padding-left: 70px;
}
.write-us .write-us-wrap {
  padding-right: 70px;
  padding-left: 70px;
}
.research-analysis-content {
  padding: 27px 15px;
  height: 114px;
}
.latest-news{
  margin-top: 40px;
}
.analysis-img {
  width: 100%;
  height: 240px;
  min-height: 240px;
  max-height: 300px;
}
.research-analysis-main{
  min-height: 360px;
}
.vacancy-content-main {
  padding: 30px;
}

.heading-text-content {
  padding-left: 30px;
}

.vacancy-content-main h5 {
  font-size: 23px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.vacancy-content-main p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.vacancy-content-main ul {
  margin-bottom: 0px;
  padding: 0px 20px;

}

.border-bottom-vacancy {
  border-bottom: 1px solid #ccc;
  padding-bottom: 25px !important;
}

.vacancy-content-main ul li {
  font-size: 15px;
  margin-top: 15px;
  font-weight: 300;
}

.apply-now-vacancy-button {
  padding: 8px 25px 12px 25px;
  background: #57a4d5;
  color: #fff !important;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
  margin-top: 40px;
}

.analysis-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.event-banner img{
  height: 350px;
  object-fit: cover;
  width: 100%;
}
.analysis-content {
  padding: 19px 20px;
  background-color: #fff;
}

.analysis-content .article-info {
  margin-bottom: 5.5px;
}
.topup-notification-main-warning{
  background-color: rgb(231 25 25 / 50%);
  padding: 10px 20px;
  margin-bottom: 7px;
}
.topup-notification-main{
  background-color: rgb(47 214 88 / 70%);
  padding: 10px 20px;
  margin-bottom: 7px;
}
.topup-notification-main h5, .topup-notification-main-warning h5{
  display: flex;
  color: #333;
  font-size: 15px;
  align-items: center;
  text-align: justify;
  font-weight: 400;
  line-height: 24px;
}
.topup-notification-main h5 span, .topup-notification-main-warning h5 span{
  font-weight: 700;
  display: contents;
}
.topup-notification-main h5 a, .topup-notification-main-warning h5 a{
  color: #333;
  font-size: 27px;
  padding-left: 30px;
}
.topup-notification-main h5 a .ri-close-line, .topup-notification-main-warning h5 a .ri-close-line{
  font-size: 25px;
}
.analysis-box .news-info {
  font-size: 14px;
  margin-bottom: 2px;
}

.analysis-box {
  height: 100%;
  max-height: 360px;
  background: #fff;
  min-height: 330px;
}
.cartoons-new-class{
  margin-top: 30px;
}
.opinion-sec .news-wrap {
  padding-top: 0px;
}

.opinion-sec .news-media>ul>li {
  margin-bottom: 10px;
  margin-top: 8px;
  border-bottom: 1px solid #8cc4e7;
  padding-bottom: 4px;
}

.opinion-sec .news-media>ul>li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}


.font-14 {
  font-size: 14px;
  font-family: 'OpenSansLight';
  color: #000;
}

.ambassador-btn {
  margin-top: 35px;
}

.ambassador-content .font-14 {
  line-height: 1.7;
  margin-bottom: 15px;
  color: #fff;
}

.ambassador-content .topic-top-info p {
  margin-bottom: 15px;
}

.ambassador {
  background: url(./assets/images/ambassador_bg.jpg);
  background-size: cover;
  padding: 50px 40px;
  color: #fff;
  margin-top: 40px;
  border-radius: 10px;
}

.common-topic-box {
  margin-bottom: 20px;
}

// .research-analysis-main {
//   margin-bottom: 40px;
// }

.latest-news-img {
  height: 100%;
  width: 100%;
  // border: 1px solid #a6a6a8;
}
.latest-news-img a{
  width: 100%;
}
.ambassador-btn {
  margin-top: 35px;
}

.ambassador .btn {
  margin-top: 20px;
  background-color: #fff;
  color: #4aa1d9;
  border-radius: 25px;
  padding: 5px 15px;
  font-size: 14px;
  text-transform: uppercase;
}

.ambassador .btn:hover {
  background-color: #4aa1d9;
  color: #fff;
  border-color: #4aa1d9;
}

.ambassador-img {
  margin-bottom: 0px;
  object-fit: cover;
  height: 100%;
}

.ambassador-img img {
  height: inherit;
  object-fit: cover;
}

.common-topic-top-img img {
  width: 100%;
    object-fit: contain;
    background: #cccccc26;
}

.audio {
  margin-bottom: 10px;
}

.audio audio {
  display: block;
  width: 95%;
}

.podcast-sec .common-topic-content .article-info {
  margin-bottom: 10px;
}

.podcast-sec .common-topics .media-img {
  width: 100px;
  height: 100px;
}

.podcast-sec .common-topic-box {
  position: relative;
  width: 100%;
  float: left;
}

.podcast-sec .common-topic-top-img {
  height: 215px;
  width: 45%;
  float: left;
}

.podcast-sec .common-topic-top-img img {
  width: 100%;
  object-fit: cover;
}

.podcast-sec .common-topic-content {
  height: 215px;
  padding: 15px 0px;
  color: #000;
  width: 53% !important;
  float: right;
  position: relative !important;
  background: none;
}

.podcast-sec .topic-top-info p a {
  color: #000;
}

.podcast-sec a.smallredbtn {
  font-size: 12px;
  color: #b04c23;
  text-transform: none;
  padding: 0px;
}

.podcast-sec a.btn:hover {
  text-decoration: underline !important;
}

.podcast-sec a.smallgreybtn {
  font-size: 12px;
  color: #000;
  text-transform: none;
  background-color: #ddd;
  border-radius: 10px;
  padding: 0px 3px;
  float: right;
  margin-right: 50px;
  margin-top: 4px;
}

.podcast-sec .topic-top-info h3 a {
  color: #000;
  padding-bottom: 15px;
}

.podcast-sec .published {
  color: #000;
}

.podcast-sec .news-media {
  padding-top: 25px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.borb {
  border-bottom: 1px solid #8cc4e7;
}

.borr {
  border-right: 1px solid #8cc4e7;
}

.videos {
  background: url(./assets/images/video_bg.jpg);
  background-size: cover;
  padding: 30px 30px 30px 30px;
  margin-top: 0px;
}

.reviews-wrap {
  overflow-x: hidden;
}

.videos .tranding-box-img video {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.videos .tranding-box-img {
  position: relative;
}

.videos .tranding-box-img .play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.comments-list .user-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.comments-list .user-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
}

.comments-list .user-name {
  font-size: 13px;
  font-family: 'OpenSansSemiBold';
  margin-bottom: 0;
}

.comments-list .media {
  margin-bottom: 10px;
}

.user-comments h6 {
  font-size: 12px;
  font-family: 'OpenSansLight';
  margin-bottom: 10px;
}

.user-comments .comment-date {
  color: #999999;
}

.comments-list>ul>li {
  margin-bottom: 11px;
}

.common-topics.vault {
  padding: 50px 0;
}

.write-us {
  background: url(./assets/images/cta_bg.jpg);
  background-size: cover;
  padding: 50px 0;
  color: #fff;
  text-align: center;
  margin-top: 40px;
  border-radius: 10px;
}

.readers_write {
  background: url(./assets/images/shop_bg.jpg);
  background-size: cover;
  padding: 50px 0;
  color: #fff;
  text-align: center;
  margin-top: 40px;
  border-radius: 10px;
}

.write-us-wrap p {
  color: #fff;
}

.write-us-wrap .topic-top-info {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: 'OpenSansSemiBold';
  text-transform: uppercase;
}

.write-us-btn {
  margin-top: 20px;
}

// .events .news-wrap {
//   padding-top: 0px;
// }

.events .latest-news-img-content {
  background: #fff;
  color: #000;
}

.events .article-info span {
  color: #fff;
}

.events .latest-news-img-content .article-info li:not(:last-child)::after {
  border-color: #000;
}

.events .news-media .article-info {
  margin-bottom: 3px;
}

.events .news-media .news-info {
  margin-bottom: 6px;
}

.events .latest-news-img-content .article-info {
  margin-bottom: 5px;
}

.events .latest-news-img-content .news-info {
  margin-bottom: 5px;
}

.user-comments .feed-comment {
  font-family: 'OpenSansSemiBold';
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    margin-top: 5px;
}

.events .social span {
  color: #fff;
  font-family: 'OpenSansSemiBold';
  background-color: #b7ae25;
  border-radius: 10px;
  padding: 3px 15px;
  text-transform: uppercase;
  font-size: 12px;

}

.events .media {
  padding-bottom: 2px;
}

.events .news-media>ul>li {
  margin-bottom: 7px;
}

.social-icon-feed li img {
  margin-right: 7px;
  max-width: 20px;
}

.social-icon-feed li span {
  font-size: 12px;
}

.social-icon-feed {
  margin-bottom: 0px;
}

.common-topics .news-media .article-info {
  margin-bottom: 5px;
}

.common-topics .news-media .published {
  margin-bottom: 5px;
}

.common-topics .news-media .news-info {
  margin-bottom: 10px;
}

.events .user-comments .comment-date {
  margin-bottom: 6px;
}

.events .user-comments .feed-comment {
  margin-bottom: 0px;
  margin-top: 7px;
}

.latest-news.events {
  padding: 50px 0;
}

.shop {
  background: url(./assets/images/shop_bg.jpg);
  background-size: cover;
  padding: 30px 30px 40px;
  margin-top: 40px;
  border-radius: 10px;
}

.shop .article-info li:last-child {
  font-family: 'OpenSansLight';
}

.shop .heading-four {
  font-size: 36px;
}

.shop .article-info span {
  color: #fff;
  font-family: 'OpenSansSemiBold';
  background-color: #b54e25;
  border-radius: 10px;
  padding: 3px 15px;
  text-transform: uppercase;
  font-size: 12px;

}

.shop .tranding-box-content .article-info {
  margin-bottom: 0px;
}

.shop .tranding-box-content .news-info {
  margin-bottom: 10px;
}

.shop .tranding-box-content .published {
  margin-bottom: 15px;
}

.shop .tranding-box-content .topic-top-info {
  margin-bottom: 15px;
}
.shop .tranding-box-content .topic-top-info {
  margin-bottom: 15px;
}
.shop-img {
  background-color: #f2f2f2;
  text-align: center;
  padding: 0px;
  height: auto;
}
.shop-img a{
  width: 100%;
}
.shop-img a img {
  height: 250px;
  object-position: center;
  object-fit: cover;
  min-height: 250px;
  max-height: 300px;
}

.buy-now .btn {
  width: 100%;
}

.shop .common-top-head {
  color: #fff;
}

.shop .common-top-head .view-more {
  color: #fff;
}

// .shop .now-tranding-wrap>ul>li:not(:last-child) {
//   margin-bottom: 30px;
// }

.common-top-head .heading-four {
  text-transform: uppercase;
}

.news-letter {
  padding: 0px 0 40px;
}

.news-letter-wrap {
  background-color: #5ecdac;
  border-radius: 10px;
  padding: 30px 15px;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.heading-two {
  font-size: 30px;
  font-family: 'OpenSansSemiBold';
  text-transform: uppercase;
}

.news-letter-head p {
  font-size: 14px;
  font-family: 'OpenSansLight';
}

.news-letter-form .form-control {
  border: none;
  font-size: 15px;
  font-family: 'OpenSansSemiBold';
  padding: 23px 150px 23px 40px;
  height: auto;
  border-radius: 40px;
  color: #808080;
}

.green-btn.submit {
  padding: 12px 30px;
  border-radius: 40px;
}

.news-letter-form .form-group {
  position: relative;
  margin-bottom: 0;
}

.news-letter-form .submit {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.news-letter-form .form-control:hover,
.news-letter-form .form-control:focus,
.news-letter-form .form-control:active {
  outline: none !important;
  box-shadow: none !important;
}


.footer-logo a {
  max-width: 170px;
  margin-bottom: 20px;
}

.footer-logo p {
  font-size: 12px;
  font-family: 'OpenSansLight';
  margin-bottom: 0;
}

.footer-menu {
  font-size: 13px;
  font-family: 'OpenSansSemiBold';
}

.footer-menu a {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
}

.footer-menu li {
  margin-bottom: 10px;
}

.member-sec {
  padding: 20px 25px 25px 25px;
  background-color: #005d97;
  border-radius: 10px;
  color: #fff !important;
}

.member-sec p {
  color: #fff !important;
}

.member-sec .topic-top-info {
  margin-bottom: 15px;
}

.member-btn {
  margin-top: 15px;
}

.footer-menu ul {
  margin-bottom: 0;
}

.member-sec .topic-top-info p {
  font-size: 20px;
  text-transform: uppercase;
}

.member-btn .btn {
  padding: 12px 25px;
  border-radius: 30px !important;
}
.comments-list ul li .media-body h6{
  font-size: 21px;
  padding-bottom: 10px;
}
.comments-list ul li .media{
  margin-left: 20px;
}
.comments-list ul li .media .media-body h6{
  font-size: 15px;
  padding-bottom: 0px;
}
.comments-list ul li .user-comments{
  margin-left: 20px;
}
.middle-footer {
  padding-bottom: 20px;
}

.middle-footer-left {
  margin-bottom: 20px;
}

.tranding-box-img {
  height: 233px;
}
.cartoons-right-main ul li{
  padding-bottom: 15px;
    padding-top: 0px;
    border-bottom: 1px solid #8cc4e7;
}
.cartoons-right-main ul li:last-child{
  border-bottom: none;
}
// .events .latest-news-img {
//   height: 300px;
// }

.copyright {
  font-size: 13px;
  padding: 30px 0;
  border-top: 1px solid #e6e6e6;
}

.copyright-text {
  margin-bottom: 0;
}

.copyright-text li {
  position: relative;
  margin-right: 11px;
}

.copyright-text li:not(:last-child)::after {
  position: absolute;
  content: "";
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid #000;
  height: 70%;
}

.footer-social span {
  display: inline-block;
  margin-right: 20px;
}

.social-media {
  margin-bottom: 0;
}

.social-media li:not(:last-child) {
  margin-right: 15px;
}

.top-menu-nav .navbar-toggler:hover,
.top-menu-nav .navbar-toggler:focus,
.top-menu-nav .navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.sub-menu {
  display: none;
}

.navbar-nav>li:hover .dropdown-menu {
  display: block;
}

.navbar-nav .dropdown-menu {
  border: none;
}

.navbar-nav .dropdown-menu li {
  padding: 8px 15px;
  background-color: transparent !important;
}

.navbar-nav li.active>a {
  color: #000 !important;
}

.navbar-nav .dropdown-menu a {
  color: #000;
  white-space: break-spaces;
}

.navbar-nav .nav-link {
  color: #000 !important;
  text-transform: uppercase;
}

.navbar-nav li a {
  position: relative;
}

.navbar-nav li.dropdown>a::after {
  content: "";
  background-image: url(./assets/images/down-arrow.svg);
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 9px;
  width: 9px;
  height: 7px;
  margin-left: 5px;
  margin-bottom: 2px;
  margin-right: 15px;
}

.navbar-nav li.dropdown>a:hover::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(120deg);
}

.navbar-nav li a span {
  float: left;
}

.navbar-nav li a span:after {
  display: block;
  content: '';
  border-bottom: solid 3px #4aa0d9;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar-nav li a span:hover:after {
  transform: scaleX(1);
}

.navbar-nav li.dropdown a span:after {
  transform-origin: 0% 50%;
}

.navbar-nav .dropdown-menu li {
  color: #000;
}
.donationnow-input-number{

}
.donationnow-input-number .separate-dial-code{
  width: 100%;
}
.donationnow-input-number .iti__selected-flag{
  width: auto;
}
.donationnow-input-number input{
  // padding-left: 77px !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    outline: none;
    border-radius: 0.375rem;
}


.donation-input-number .separate-dial-code{
  width: 100%;
  margin-top: 10px;
}
.donation-input-number .iti__selected-flag{
  width: auto;
}
.donation-input-number input{
  width: 100%;
}


.applyform-input-number .separate-dial-code{
  width: 100%;
}
.applyform-input-number .iti__selected-flag{
  width: auto;
}
.applyform-input-number input{
  width: 100%;
  background: transparent;
  border: transparent;
  border-bottom: 1px solid #b2b2b2;
  color: #808080;
  margin-bottom: 0px;
  margin-top: 0px;
  outline: none !important;
  font-size: 16px;
  height: 35px;
}

.sidebar-element-js-main{

}
.sidebar-element-js-main .search {
  width: 100%;
  max-width: 100%;
}
.sidebar-element-js-main .login-listng {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sidebar-element-js-main .header-new-menu-left li{
  padding: 10px 15px;
  padding-bottom: 0px;
}
.sidebar-element-js-main .header-new-menu-left li a{
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: uppercase;
}
.sidebar-element-js-main .header-new-menu-left li button{
  padding: 0px;
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: uppercase;
  display: contents;
}
.sidebar-element-js-main .header-new-menu-left #home-collapse li a{
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: capitalize;
}
.sidebar-element-js-main .header-new-menu-left #home-collapse li button{
  padding: 0px;
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: capitalize;
}
.sidebar-element-js-main .header-new-menu-left #orders-collapse li a{
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: capitalize;
}
.sidebar-element-js-main .header-new-menu-left #orders-collapse li button{
  padding: 0px;
  font-size: 15px;
  border: none !important;
  font-family: OpenSansSemiBold;
  text-transform: capitalize;
}
.sidebar-element-js-main [sidebarjs-container]{
    min-width: 380px;
    max-width: 380px;
    min-height: 380px;
    max-height: 100%;
    overflow: auto;
    height: 100%;
}
.sidebar-element-js-main [sidebarjs-container] .flex-shrink-0.p-3.bg-white{
  width: 360px !important;
}
// .navbar-nav {
//   font-size: 16px;
// }

.search .form-control {
  height: auto;
  background: #f2f2f2 !important;
  border: none;
  border-radius: 20px;
  font-size: 13px;
  padding: 7px 40px 7px 15px;
  max-width: 425px;
}

.search .form-group {
  margin-bottom: 0;
  position: relative;
}

.search input[type="submit"], .search input[type="button"] {
  font-size: 0;
  background-color: #4aa0d9;
  border: none;
  background-image: url(./assets/images/search.svg);
  background-position: center;
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  top: 3px;
  right: 3px;
  border-radius: 50%;
}

.search .form-control:hover,
.search .form-control:focus,
.search .form-control:active {
  outline: none;
  box-shadow: none;
}

.bottom-header .navbar-collapse {
  -ms-flex-positive: initial;
  flex-grow: initial;
}

.search {
  width: 100%;
  max-width: 215px;
}

.language-dropdown .btn {
  padding: 7px;
}
.banner .slide-image{
  object-fit: cover;
  max-width: 100%;
}
.language-dropdown .dropdown-menu {
  min-width: initial;
  border: none;
  border-radius: 0;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .15);
  padding: 0px !important;
  background-color: #efefef;
}

.language-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #ccc;
}

.inner-banner {
  min-height: 300px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  background-position: center;
}

.inner-banner-content .heading-two {
  margin-bottom: 0;
}

.inner-banner-content {
  color: #fff;
}

.contact-form label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #808080;
  font-size: 16px;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.contact-form label img {
  margin-right: 12px;
}

.contact-form input,
.contact-form textarea {
  height: auto;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #b2b2b2 !important;
  color: #808080;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}

.contact-form input:hover,
.contact-form input:focus,
.contact-form input:active,
.contact-form textarea:hover,
.contact-form textarea:focus,
.contact-form textarea:active {
  outline: none;
  box-shadow: none;
}

.contact-form .heading-two {
  margin-bottom: 30px;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form textarea {
  width: 100%;
  resize: none;
}

.contact-form .green-btn {
  color: #fff;
  border-radius: 30px;
  font-size: 15px;
  padding: 12px 30px;
  min-width: 200px;
}

.contact-form .green-btn:hover,
.contact-form .green-btn:focus,
.contact-form .green-btn:active {
  color: #00a8a8;
}

.here-from-you .heading-four {
  margin-bottom: 20px;
}

.donations-main-left .heading-four {
  margin-bottom: 20px;
}

.here-from-you p {
  font-size: 15px;
  text-align: justify;
}

.here-from-you {
  margin-bottom: 25px;
  margin-right: 60px;
}
.contact-address{
  margin-right: 60px;
}
.contact-address>.heading-four {
  margin-bottom: 20px;
  font-family: "OpenSansRegular";
  font-size: 20px;
}

.contact-list {
  font-size: 15px;
  margin-bottom: 30px
}

.contact-list li {
  margin-bottom: 15px;
}

.contact-list a {
  color: #000;
  font-size: 18px;
}
.contact-social-wrap h4{
  font-size: 32px;
  margin-bottom: 30px;
}
.contact-list img {
  margin-right: 15px;
}


.contact-social-wrap .fb {
  width: 10px;
}

.contact-social-wrap .insta {
  width: 20px;
}

.contact-social-wrap .twitter {
  width: 25px;
}

.contact-social-wrap .youtube {
  width: 30px;
}

.contact-main {
  padding: 50px 0 5px;
}

.black-btn {
  border: 2px solid #000000;
  border-radius: 20px;
  font-family: 'OpenSansSemiBold';
  font-size: 15px;
  background-color: #000;
  color: #fff !important;
}

.black-btn img {
  margin-right: 5px;
}


.header-social .become-member-btn {
  margin-right: 7%;
}

.language-dropdown .dropdown-item {
  padding-left: 10px;
  padding-right: 10px;
}

.top-menu-nav .navbar-collapse .login-listng {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.top-menu-nav>li:last-child {
  margin-right: 0;
}


.top-header .search .form-control {
  background-color: #fff;
}

.top-header .search {
  padding: 30px 15px 0;
}

.top-menu-nav {
  margin-bottom: 0;
}

.article-detail {
  padding: 45px 0 0px;
}

.share-icons {
  margin-bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  font-size: 9px;
  font-family: 'OpenSansLight';
  justify-content: end;
}

.progress-circle.svg-content .progress-circle {
  position: relative;
  top: -1px;
  left: -2px;
}
.left-menu-new button{
  color: #6e6a6a !important;
  padding-left: 15px !important;
  font-size: 15px;
  font-weight: 500;
  background: #fff !important;
}
.left-menu-new button:hover{
  background-color: #fff !important;
}
.left-menu-new-button{
  position: relative;
}
.left-menu-new-button button.navbar-toggler.collapsed:after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(110,106,106,1)'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  position: absolute;
  background-size: 20px;
  margin-left: 6px;
  margin-top: -2px;
}
.left-menu-new-button button.navbar-toggler:after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z' fill='rgba(110,106,106,1)'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  position: absolute;
  background-size: 20px;
  margin-left: 6px;
  margin-top: -2px;
}
.general-information-left-arrow{
  position: relative;
}
.general-information-left-arrow a{

}
.general-information-left-arrow a:after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  position: absolute;
  background-size: 20px;
  margin-left: 6px;
  margin-top: 0px;
}
.left-menu-new .collapse{
  margin-top: 10px;
}
.left-menu-new .collapse a{
  padding-bottom: 10px;
  background: #fff !important;
}
.share-icons li:not(:last-child) {
  margin-right: 20px;
}

.share-icons a {
  color: #000;
}

.share-icons a:hover,
.share-icons a:focus,
.share-icons a:active {
  text-decoration: none;
}

.share-icons a span {
  margin-left: 3px;
  display: inline-block;
}
.public-summary{
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding: 20px;
}
.public-summary h3{
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.public-summary h3 span p{
  color: #333;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
  padding-top: 20px;
  line-height: 25px;
}
.article-detail-top .article-info {
  margin-bottom: 20px;
}
.modal-top-new-class{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.modal-open .fixed{
  z-index: 1 !important;
}
.modal-top-new-class .btn-close{
  margin-top: 0px !important;
  outline: none !important;
}
.modal-top-new-class .btn-close:focus{
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}
.heading-five {
  font-size: 16px;
  font-family: 'OpenSansSemiBold';
  color: #000;
}

.article-detail-top .heading-five {
  margin-bottom: 15px;
  font-size: 40px;
}

.article-detail-top {
  margin-bottom: 35px;
}

.article-img {
  margin-bottom: 35px;
}

.article-img-detail {
  font-size: 15px;
  margin-bottom: 35px;
}

.article-comment {
  font-size: 16px;
  color: #808080;
  margin-bottom: 35px;
}

.article-comment textarea {
  border: 1px solid #b2b2b2;
  border-radius: 10px;
  width: 100%;
  background-image: url("./assets/images/chat.svg");
  background-repeat: no-repeat;
  background-position-x: 15px;
  background-position-y: 35px;
  background-size: 20px;
  padding: 30px 15px 30px 50px;
  min-height: 220px;
  resize: none;
}

.article-comment textarea:hover,
.article-comment textarea:focus,
.article-comment textarea:active {
  outline: none;
  box-shadow: none;
}

.article-comment .form-group {
  margin-bottom: 20px
}

.article-comment input[type="submit"] {
  padding: 12px 30px;
  min-width: 200px;
  border-radius: 30px;
}

.article-comment-list .user-img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.article-comment-list .heading-four {
  margin-bottom: 5px;
}

.article-comment-list .user-name {
  font-family: 'OpenSansRegular';
}
.date-icon-filter button{
  height: 42px;
}
.article-comment-list .media {
  margin-bottom: 12px;
}

.article-comment-list .topic-top-info p {
  font-family: 'OpenSansRegular';
}

.article-comment-list>ul>li:last-child {
  margin-bottom: 0;
}

.article-comment-list>ul>li:last-child p:last-child {
  margin-bottom: 0;
}

.article-comment-list>ul {
  margin-bottom: 0;
}

.article-detail-top .published {
  margin-bottom: 20px;
}

.article-tag {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.article-tag a {
  font-size: 11px;
  padding: 5px 8px;
  background-color: #f2f2f2;
  color: #808080;
  border-radius: 5px;
}
.new-class-tranding-main{
  height: auto;
}
.new-class-tranding-main a{
  width: 100%;
}
.new-class-tranding-main a img{
  width: 100%;
  height: 100%;
  max-height: 300px;
  min-height: 300px;
  object-fit: cover;
  background: rgba(204, 204, 204, 0.231372549);
}

.article-tag li {
  margin-right: 5px;
}

.article-tag li:last-child {
  margin-right: 0;
}

.article-tag a:hover,
.article-tag a:focus,
.article-tag a:active {
  background-color: #5ba5d3;
  color: #fff;
  text-decoration: none;
}

.share-icons img:hover {
  filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(1.2);
}

.pricing-listing {
  margin-bottom: 30px;
}

.pricing-listing li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
}

.pricing-listing li::before {
  position: absolute;
  content: "";
  left: 0;
  background-image: url("./assets/images/checked-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  width: 24px;
  height: 24px;
}

.pricing-listing li p {
  margin-bottom: 0;
}

.pricing-listing li:last-child {
  margin-bottom: 0;
}
#thank-you .modal-dialog {
  max-width: 400px;
}
.thank-you-sign-up{
  padding-top: 10px;
  padding-bottom: 10px;
}
.thank-you-sign-up img{
  width: 110px;
}
.thank-you-sign-up h4{
 font-size: 20px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 30px;
  line-height: 30px;
  padding-bottom: 30px;
}
.thank-you-sign-up a{
  background: #0067b0;
  color: #fff !important;
  padding: 10px 20px 12px 20px;
  border-radius: 10px;
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid #0067b0;
}
.modal-dialog.modal-md, .modal-dialog.modal-lg{
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  // width: 540px;
}
.black-outline-btn {
  border: 2px solid #000000;
  border-radius: 20px;
  font-family: 'OpenSansSemiBold';
  font-size: 15px;
  color: #000;
}

.black-outline-btn:hover,
.black-outline-btn:focus,
.black-outline-btn:active {
  color: #fff;
  background-color: #000;
}

.buy-now-btn {
  text-align: center;
}

.pricing-top-content {
  padding: 40px 0;
}

.pricing-top-content p:last-child {
  margin-bottom: 0;
}

.pricing-top-content p {
  font-size: 16px;
}

.pricing-box p {
  font-size: 16px;
}

.pricing-standard {
  font-family: 'OpenSansBold';
  margin-bottom: 10px;
  text-transform: capitalize;
}

.box-price {
  font-size: 35px;
  font-family: 'OpenSansExtraBold';
  margin-bottom: 0;
  line-height: 1;
}

.pricing-top-box {
  padding: 25px 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #c2e1d7;
}

/*.basic-pricing .pricing-top-box{background-color: #c2e1d7;}*/
.standard-pricing .pricing-top-box {
  background-color: #00a8a8;
  color: #fff;
}

.pricing-short-info {
  margin-bottom: 0;
}

.pricing-bottom-box {
  padding: 30px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pricing-box-wrap {
  margin-bottom: 40px;
}

.pricing-box {
  height: 100%;
  background-color: #fafafa;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pricing-bottom-box .buy-now-btn {
  margin-top: auto;
}

.blog-detail-top-content .article-info {
  margin-bottom: 10px;
}

.blog-detail-top-content .heading-five {
  margin-bottom: 10px;
}

.blog-detail-top-content {
  margin-bottom: 15px;
}

.blog-content p+p {
  margin-top: 20px;
}

.article-share-icons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog-img {
  height: 400px;
}

.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.article-blog .heading-two {
  font-family: 'OpenSansSemiBold';
  margin-bottom: 30px;
}

.article-blog {
  padding: 50px 0 120px;
}

.article-listing-wrap {
  margin: 20px 0px 20px 0px;
  padding: 0px !important;
  box-shadow: 2px 3px 5px #ccc;
}

.blog-detail .article-tag {
  margin-bottom: 12px;
}

.blog-img a {
  width: 100%;
  height: 100%;
}

.heading-five a {
  color: #000;
}

.news-info a {
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.latest-news-img-content .news-info a {
  color: #fff;
}

.topic-top-info p a {
  color: #000;
}

.events .latest-news-img-content .news-info a {
  color: #000;
}

.events .latest-news-img-content .published {
  color: #000;
}

.article-list-content .article-tag a {
  padding: 5px 5px;
}

.article-listing-img {
  margin-bottom: 20px;
}
.article-listing-img a{
  width: 100%;
}
.article-list-content .article-info {
  margin-bottom: 8px;
}

.article-list-content .published {
  margin-bottom: 8px;
}

.article-list-content .article-tag {
  margin-bottom: 10px;
}

.article-list-content {
  padding: 10px;
}

.vacancy-content-main h1 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

.vacancy-listing-wrap .article-listing-img {
  margin-bottom: 0px;
}

.vacancy-content-main h6 {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.vacancy-content-main h6 .ri-map-pin-line {
  font-size: 25px;
  color: #57a4d5;
  margin-right: 10px;
}

.vacancy-content-main h6 .ri-user-line {
  font-size: 25px;
  color: #57a4d5;
  margin-right: 10px;
}

.load-more {
  text-align: center;
}

.load-more .black-outline-btn {
  min-width: 200px;
  padding: 10px 22px;
  border-radius: 40px;
}

.article-listing {
  margin-bottom: 0;
}

.article-listing-img img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: cover;
  background: rgba(204, 204, 204, 0.231372549);
  min-height: 300px;
}

.shop.shop-main {
  background-color: #fff;
  padding: 50px 0 0;
}

.shop.shop-main .common-top-head {
  color: #000;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sorting {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.sorting .form-control {
  width: auto;
  border: none;
  background-color: #f2f2f2;
  color: #000;
  height: auto;
  padding: 10px 35px 10px 15px;
  font-size: 15px;
  font-family: 'OpenSansRegular';
  max-width: 130px;
  border-radius: 20px;
}

.sorting select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(./assets/images/down-arrow.svg);
  background-position-x: calc(100% - 12px);
  background-position-y: center;
  background-repeat: no-repeat;
}

.sorting label {
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 15px;
  font-family: 'OpenSansRegular';
  color: #999999;
}

.sorting .form-control:hover,
.sorting .form-control:focus,
.sorting .form-control:active {
  box-shadow: none;
  outline: none;
}

.shop.shop-main .common-top-head .heading-two {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.shop.shop-main .common-top-head .sorting {
  margin-bottom: 20px;
}

.shop.shop-main .now-tranding-wrap>ul>li {
  margin-bottom: 25px !important;
}

.heading-three {
  font-size: 24px;
  font-family: 'OpenSansSemiBold';
}

.mb-xl-30 {
  margin-bottom: 25px;
}

.article-detail-box p {
  font-size: 15px;
  clear: both;
  color: #333;
  font-weight: 500;
  line-height: 30px;
}

.article-detail-box {
  margin-bottom: 40px;
}

.mb-xl-20 {
  margin-bottom: 20px;
}

.article-detail-inner-box {
  background-color: #f2f2f2;
  padding: 35px 15px;
  margin-bottom: 40px;
}

.article-detail-inner-box p:last-child {
  margin-bottom: 10px;
}

.mb-xl-25 {
  margin-bottom: 20px;
}

.reference-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.ref-num {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #00a8a8;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  font-size: 15px;
}

.ref-detail {
  width: calc(100% - 45px);
  font-size: 16px;
}

.ref-detail p {
  margin-bottom: 0;
}

.ref-detail a {
  color: #00a8a8;
  word-break: break-all;
}

.reference-list li .ref-detail li {
  display: block;
  color: #00a8a8;
  float: left;
  padding: 0px 10px;
}

.article-detail-box.analysis-report {
  margin-bottom: 40px;
}

.article-impect {
  margin-bottom: 40px;
}

.article-detail-inner-box.on-local {
  margin-bottom: 40px;
}

.reference-list li:last-child {
  margin-bottom: 0;
}

.reference-list {
  margin-bottom: 0;
}

.review-img {
  height: 150px;
  width: 100%;
}
.review-img a{
  width: 100%;
}
.review-img a img{
  object-fit: contain;
  background-color:  rgba(204, 204, 204, 0.231372549);
  height: 150px;
}
.review-img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

#sign-up-banner {
  background: #cdebed;
  padding-top: 60px;
  padding-bottom: 60px;
}

.radio-h6-input {
  display: flex;
  margin-top: 13px;
}

.radio-h6-input h6 {
  margin-bottom: 0px;
  margin-right: 10px;
  display: flex;
  font-size: 15px;
  color: #888;
  font-weight: 600;
}

.plan-bg-main {
  background: #fff;
  border: 1px solid #a8a9ad;
  border-radius: 10px;
}

.plan-bg-one {
  background: #e6e6e8;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 9px;
}

.plan-bg-main h4 {
  margin-bottom: 0px;
  font-size: 23px;
  font-weight: 600;
  color: #231f20;
}

.plan-bg-main p {
  font-size: 13px;
  text-align: center;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  min-height: 120px;
}

.plan-bg-main h1 {
  color: #231f20;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  padding-top: 45px;
  padding-bottom: 45px;
}
.plan-bg-main h1 span{
  text-decoration: line-through;
    font-size: 14px;
    color: #878787;
    padding-left: 10px;
}
.plan-bg-main h5 {
  color: #231f20;
  font-size: 16px;
  text-align: center;
  font-weight: 900;
}

.plan-bg-main ul {}

.plan-bg-main ul li {
  font-size: 14px;
  font-weight: 500;
}

.plan-bg-main-ul-right {
  padding: 0px;
}

.border-top-sign-up {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 50px;
  margin-bottom: 50px;
}

.plan-bg-main:hover {
  border: 1px solid #00a8a8;
  transform: scale(1.1);
  box-shadow: 2px 2px 10px 2px #00a8a826;
}

.plan-bg-main:hover .plan-bg-one {
  background: #00a8a8;
}

.plan-bg-main:hover h4 {
  color: #fff;
}

.plan-bg-main:hover h1 {
  color: #00a8a8;
}

.plan-bg-main:hover h5 {
  color: #00a8a8;
}



.plan-bg-main.active {
  border: 1px solid #00a8a8;
  box-shadow: 2px 2px 10px 2px #00a8a826;
  transform: scale(1.1);
}

.plan-bg-main.active .plan-bg-one {
  background: #00a8a8;
}

.plan-bg-main.active h4 {
  color: #fff;
}

.plan-bg-main.active h1 {
  color: #00a8a8;
}

.plan-bg-main.active h5 {
  color: #00a8a8;
}

.plan-bg-main.active .plan-bg-button button {
  border: 1px solid #00a7af;
  color: #fff;
  background-color: #00a7af;
  cursor: pointer;
}


.plan-bg-main:hover {
  border: 1px solid #00a8a8;
  box-shadow: 2px 2px 10px 2px #00a8a826;
  transform: scale(1.1);
}

.plan-bg-main:hover .plan-bg-one {
  background: #00a8a8;
}

.plan-bg-main:hover h4 {
  color: #fff;
}

.plan-bg-main:hover h1 {
  color: #00a8a8;
}

.plan-bg-main:hover h5 {
  color: #00a8a8;
}

.plan-bg-main:hover .plan-bg-button button {
  border: 1px solid #00a7af;
  color: #fff;
  background-color: #00a7af;
  cursor: pointer;
}

// .plan-bg-main:hover {
//   border: 1px solid #a8a9ad;
//   box-shadow: 0px 0px 0px 0px #00a8a826;
// }

// .plan-bg-main:hover .plan-bg-one {
//   background: #e6e6e8;
// }

// .plan-bg-main:hover h4 {
//   color: #231f20;
// }

// .plan-bg-main:hover h1 {
//   color: #231f20;
// }

// .plan-bg-main:hover h5 {
//   color: #231f20;
// }

// .plan-bg-main:hover .plan-bg-button button {
//   border: 1px solid #00a7af;
//   color: #00a7af;
//   background-color: transparent;
//   cursor: pointer;
// }

/*.plan-bg-main.active{
transform: scale(1.1);
}*/

.plan-bg-button {
  display: flex;
  justify-content: center;
}

.plan-bg-button button {
  background: transparent;
  border: 1px solid #00a7af;
  color: #00a7af;
  padding: 6px 18px;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 12px;
  cursor: pointer;
  text-transform: uppercase;
}

.plan-bg-main:hover .plan-bg-button button {
  border: 1px solid #00a7af;
  color: #fff;
  background-color: #00a7af;
  cursor: pointer;
}

.clock {
  padding: 0px 5px;
}


/* #Progress
================================================== */

.clock {
  padding: 0px 5px;
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: '⬆';
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: #4aa0d9;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::after {
  opacity: 0;
}

.progress-wrap::before {
  position: absolute;

  content: '⬆';
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background-image: linear-gradient(30deg, deepskyblue, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::before {
  opacity: 1;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #4aa0d9;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #4aa0d9;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: var(--black-blue);
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4aa0d9;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:hover {
  box-shadow: 0 8px 8px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: red;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: var(--yellow);
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}


.sticky-top {
  background-color: #fff;
}

.top-header.fixed-top {
  z-index: 9999;
  position: fixed !important;
}

.top-header.nf-top {
  z-index: 9999;
}

.top-header.fixed-top .navbar-brand {
  width: 100px !important;
}

.fixed-top {
  box-shadow: 5px 5px 5px 5px #2222220d;
}

html {
  scroll-behavior: smooth;
}

.vault .col-xl-4 {
  padding-right: 0px !important;
}


.top-menu-nav .navbar-nav .nav-link {
  font-size: 15px;
}

.top-menu-nav .navbar-nav li.active>a {
  font-size: 15px;
}

.top-menu-nav .navbar-collapse {
  background-color: #fff !important;
}

.top-header .search .form-control {
  background-color: #f2f2f2;
}

.top-menu-nav .side-menu .dropdown-menu {
  display: none !important;
}

.top-menu-nav .side-menu li::hover .dropdown-menu {
  display: block !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.top-menu-nav .side-menu .dropdown-menu a {
  color: #6e6a6a !important;
  padding-left: 15px !important;
}

.top-menu-nav .side-menu li:focus-within .dropdown-menu {
  display: block !important;
}

.top-menu-nav .navbar-nav li.active>a:hover .dropdown-menu {
  display: block !important;
  // -webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1) transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1)
}

/*.top-menu-nav .side-menu li .dropdown-menu .hassub .sub-menu{display: none!important;}
.top-menu-nav .side-menu li .dropdown-menu .hassub:hover .sub-menu{display: block!important;}*/
.top-header .navbar-collapse .search .form-control {
  background-color: #fff !important;
  border-bottom: 1px solid #000;
  border-radius: 0;
  padding-bottom: 13px;
}

.top-menu-nav .navbar-collapse .login-listng {
  padding: 0px !important;
}

.top-menu-nav .navbar-collapse {
  width: 300px !important;
  display: block;
  position: absolute;
  top: 66px;
  left: -35px;
  transform: translateX(-200%);
  height: 100vh !important;
  background: #f2f2f2;
  z-index: 999;
  overflow-y: auto;
  transition: all 0.3s fadein;

}

.top-menu-nav .navbar-nav li {
  width: 100% !important
}

.header-social {
  margin: 20px !important;
}

.header-social .become-member-btn {
  margin: 20px !important;
}

.footer-social a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  padding: 9px 5px 5px 5px;
}

.footer-social a:hover {
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  background-color: #4aa0d9;
  transition: 1000ms linear;
  transform: scale(1.2);
}

.footer-social a:hover img {
  filter: invert(1);
  transition: 500ms linear;
  transform: scale(0.9);
}

.top-menu-nav .navbar-collapse .footer-social a {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  padding: 5px 5px 5px 5px;
}

.top-menu-nav .navbar-collapse .footer-social a:hover {
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  background-color: #4aa0d9;
  transition: 1000ms linear;
  transform: scale(1.2);
}

.top-menu-nav .navbar-collapse .footer-social a:hover img {
  filter: invert(1);
  transition: 500ms linear;
  transform: scale(0.9);
}


.navbar {
  padding: 0px !important;
}

body.container {
  padding: 0px !important;
}

section.article-detail .article-img_overlay {
  height: 100px;
  width: 45%;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  top: -171px;
  right: 5px;
  float: right;
  padding: 30px 20px;
  color: #fff;
  font-size: 16px;
}

.article-detail-box {
  margin-bottom: 0px !important;
}

.supplymentry {
  padding: 50px 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.supplymentry-img {
  width: 100px;
  float: left;
  padding: 10px 0px;
  border: 1px solid #4fa1d7;
  border-radius: 10px;
  margin-top: 10px;
}

.supplymentry-img img {
  width: 100%;
}

.supplymentry-content {
  width: 200px;
  padding-left: 10px;
  float: left;
  margin-top: 10px;
}

.supplymentry.beforelogin {
  border-top: none;
}

.getfullaccess {
  padding: 50px 50px;
  background-color: #f2f2f2;
  margin-bottom: 30px;
  text-align: center;
}

.getfullaccess .row {
  display: block !important;
}

.getfullaccess .row button {
  margin-bottom: 20px;
}

a {
  color: #4fa1d7;
}

.ref-detail ul {
  padding: 0px;
}

// html,
// body {
//   height: 100%;
// }

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }


@media screen and (min-device-width: 320px) and (max-device-width: 380px) {
  .navbar-brand {
    max-width: 100px;
  }

  .news-letter-form .submit {
    padding: 10px 15px;
    font-size: 13px;
  }

  .language-dropdown .btn {
    padding: 4px;
  }

  .top-menu-nav>li {
    margin-right: 20px;
  }

  .community-wrap h2 {
    font-size: 18px;
  }

  .community .btn {
    font-size: 14px;
  }

  .ambassador-content {
    padding-top: 20px;
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .bottom-header {
    display: none;
  }
  .without-login{
    display: none !important;
  }
  .article-info span {
    display: block;
  }
  .topup-notification-main{
    display: none;
  }
  .subscribe-login-in{
      display: block !important;
  }
  .subscribe-login-in button{
      width: 100% !important;
  }
  .top-menu-sec {
    position: relative;
    z-index: 999;
  }
  .with-login{
    margin-top: 20px;
  }
  .plan-bg-main ul {
    min-height: auto !important;
  }
  .sign-up-banner-left h2 {
    color: #00a8af;
    font-size: 30px !important;
    padding-left: 40px !important;
    font-weight: 600 !important;
    padding-right: 70px;
    padding-bottom: 20px;
  }
  .donations-main-left{
    padding-right: 10px !important;
    margin-bottom: 40px !important;
  }
  #team-details{
    padding-top: 50px !important;
  }
  #my-settings{
    min-height: auto !important;
    margin-top: 50px !important;
  } 
  .product-img img {
    height: auto !important;
    object-fit: contain !important;
    margin-bottom: 40px !important;
  }
  .login-box h2 {
    font-size: 28px !important;
  }
  .cancel-button {
    font-size: 14px !important;
    border-radius: 30px !important;
    padding: 6px 34px 6px 34px !important;
  }
  .login-box input[type=submit]{
    font-size: 14px !important;
    border-radius: 30px !important;
    padding: 6px 34px 8px 34px !important;
  }
  .inner-banner-content .heading-two {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .login-listng li a {
    font-size: 13px;
    padding: 4px 14px 4px 14px;
  }
  .here-from-you {
    margin-bottom: 25px;
    margin-right: 0px;
  }
  .contact-form label img {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  #sign-up-banner {
    background: #cdebed;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .plan-bg-main h1{
    color: #231f20;
    font-size: 30px !important;
    text-align: center;
    font-weight: 600;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .getfullaccess .d-flex{
    display: block !important;
  }
  .edit-ri-line {
    position: relative !important;
    right: 0px !important;
    top: 0px !important;
    border: 1px solid #333 !important;
    padding: 4px 10px 5px 10px !important;
    border-radius: 20px !important;
    background: #333 !important;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 13px;
  }
  .boxshadow-dashboard{
    box-shadow: 0px 0px 10px 0px rgb(120 120 120 / 48%);
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    position: relative;
    min-height: auto;
  }
  .box-full-access {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgb(120 120 120 / 41%);
    padding: 20px 20px;
    width: 100%;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .box-full-access h4, .box-full-access .h4 {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .box-full-access button{
    font-size: 11px;
  }
  .comment-message-box img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    margin-right: 10px;
    background-size: 45px;
    max-width: 45px;
    max-height: 45px;
  }
  .comment-message-box h5{
    font-size: 17px;
  }
  .comment-message-box h4 a{
    font-size: 13px;
  }
  .comment-message-box h3{
    font-size: 13px;
  }
  .comment-message-box h6{
    font-size: 14px;
  }
  .latest-news {
    margin-top: 0px;
  }
  .navbar-brand {
    margin: 0 auto;
    max-width: 120px;
  }

  .heading-one {
    font-size: 40px
  }

  .blog-img {
    height: 300px;
  }

  .heading-one {
    font-size: 30px;
  }
  .news-cover {
      margin-top: 20px;
  }
  .faq-search-main{
    display: block !important;
  }
  .faq-search-main button{
    float: left !important;
    margin-bottom: 10px !important;
    width: 100% !important;
  }
  .faq-search-main .search {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    justify-content: end;
    align-items: end;
    min-width: 100%;
  }
  .banner-slider {
    padding: 0px 0;
    max-height: 500px !important;
    min-height: 500px !important;
    height: 100%;
  }

  .banner-slider .heading-one {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px !important;
  }

  .banner-slider p {
    margin-bottom: 20px;
    font-size: 21px;
    font-size: 16px;
    width: 100%;
  }

  .banner-content {
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    min-height: 400px;
    max-height: 400px;
  }
.banner .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 400px !important;
    max-height: 500px !important;
}
.banner .owl-carousel .owl-item{
  min-height: 400px !important;
  max-height: 500px !important;
}
  .heading-four {
    font-size: 22px;
    margin-bottom: 0px;
  }
  .article-detail-top .heading-five {
    margin-bottom: 15px;
    font-size: 28px;
  }
.inner-banner {
    min-height: 200px;
}
  .news-bottom-article {
    margin-bottom: 0px;
  }

  .biomedical {
    margin-bottom: 50px;
  }

  .community {
    height: 100%;
    min-height: 270px;
  }

  .latest-news {
    padding: 0px 0 80px;
  }

  .review-main-box>li {
    margin-bottom: 50px;
  }

  .now-tranding {
    padding: 20px 20px;
    margin-top: 30px;
  }
  .tranding-box {
      height: 100%;
      margin-top: 20px;
  }
  .common-topic-content {
    padding: 25px;
  }

  .topic-top-info p {
    font-size: 18px;
  }

  .common-topic-content {
    position: absolute;
    left: -1px;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    width: 100% !important;
  }
.podcast-sec .common-topic-top-img {
    height: 215px;
    width: 100%;
    float: left;
}
.podcast-sec .common-topic-content{
  width: 100% !important;
  height: auto;
}
  .common-topic-top-img {
    margin-bottom: 0px;
  }

  .analysis-content {
    padding: 15px 20px;
  }

  .latest-news.opinion-sec {
    padding: 60px 0 80px;
  }

  .font-14 {
    font-size: 14px;
  }
  .getfullaccess h3{
    font-size: 16px;
  }
  .getfullaccess p{
    font-size: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .ambassador {
    padding: 35px 15px;
  }
  .modal-dialog.modal-md {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
    width: auto;
}
.plan-bg-main {
  border-radius: 10px;
  margin-bottom: 20px;
}
.plan-bg-main.active {
  transform: scale(1);
}
  .ambassador-content {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ambassador-btn {
    margin-top: 0px;
  }
  .write-us .write-us-wrap {
    padding-right: 20px;
    padding-left: 20px; 
  }
  .common-topic-box {
    margin-bottom: 0px;
  }
  .readers_write .write-us-wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  .common-topics.podcast-sec {
    padding: 0px 0 20px;
  }
  .common-topics {
    padding: 0px 0 50px;
  }
  .comments-list .user-name {
    font-size: 15px;
  }

  .comments-list>ul>li {
    margin-bottom: 10px;
  }

  .common-topics.vault {
    padding:20px 0 20px;
  }

  .write-us {
    padding:30px 0 30px 0px;
  }

  .latest-news.events {
    padding: 0px 0 0px;
    margin-top: 0px;
  }

  // .shop {
  //   padding: 65px 0;
  // }

  // .shop .now-tranding-wrap>ul>li:not(:last-child) {
  //   margin-bottom: 40px;
  // }

  .news-letter {
    padding:0px 0 35px;
  }

  .news-letter-wrap {
    padding: 40px 30px;
  }

  .heading-two {
    font-size: 25px;
  }
  
  .news-letter-head p {
    font-size: 14px;
  }

  .news-letter-form .form-control {
    font-size: 15px;
    padding: 15px 120px 15px 20px;
  }

  .footer-logo p {
    font-size: 12px;
  }

  .footer-menu {
    font-size: 14px;
  }

  .member-sec .topic-top-info {
    margin-bottom: 20px;
  }

  .member-btn {
    margin-top: 20px;
  }

  .footer-logo-main {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .footer-menu-main {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .footer-menu li:last-child {
    margin-bottom: 0;
  }

  .copyright {
    font-size: 13px;
  }

  .copyright {
    padding: 40px 0;
  }

  .search .form-control {
    font-size: 15px;
  }

  .search {
    max-width: 375px;
  }

  .contact-form label {
    font-size: 18px
  }

  .contact-form .heading-two {
    margin-bottom: 40px;
  }

  .contact-form .form-group {
    margin-bottom: 30px;
  }

  .contact-main {
    padding: 40px 0 15px;
  }
  .contact-address {
    margin-right: 10px;
    margin-bottom: 30px;
  }
  .contact-address > .heading-four {
    margin-bottom: 20px;
    font-family: "OpenSansRegular";
    font-size: 17px;
    line-height: 28px;
  }
  .close-menu {
    margin-top: 50px;
  }

  .top-menu-nav .navbar-nav .dropdown-menu {
    margin-bottom: 25px;
  }

  .top-menu-nav .navbar-nav .dropdown-menu.sub-menu {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-social {
    margin-bottom: 50px;
  }

  .article-detail {
    padding: 10px 0 0px;
  }
  .ambrasador-main-four{
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .heading-five {
    font-size: 18px;
  }

  .article-img-detail p {
    margin-bottom: 20px;
  }

  .article-comment textarea {
    padding: 50px 15px 50px 70px;
    background-position-x: 25px;
    background-position-y: 55px;
    background-size: 25px;
  }

  .article-comment {
    font-size: 18px;
  }

  .article-comment .form-group {
    margin-bottom: 25px
  }

  .share-icons {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .article-detail-top .published {
    margin-bottom: 0px;
  }

  .pricing-listing li {
    margin-bottom: 20px;
  }

  .pricing-listing {
    margin-bottom: 40px;
  }

  .pricing-top-content {
    padding: 50px 0;
  }

  .pricing-top-content p {
    font-size: 18px;
  }

  .pricing-box p {
    font-size: 18px;
  }

  .box-price {
    font-size: 45px;
  }

  .pricing-top-box {
    padding: 30px 25px;
  }

  .pricing-bottom-box {
    padding: 35px 25px;
  }

  .blog-img {
    height: 400px;
  }

  .blog-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .article-blog {
    padding: 50px 0 70px;
  }

  .article-listing-img {
    height: auto;
    width: 100%;
  }

  .article-listing-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .shop.shop-main .common-top-head .heading-two {
    margin-bottom: 25px;
  }

  .shop.shop-main .common-top-head .sorting {
    margin-bottom: 25px;
  }

  .shop.shop-main .now-tranding-wrap>ul>li {
    margin-bottom: 30px !important;
  }

  .article-img {
    margin-bottom: 45px;
  }

  .heading-three {
    font-size: 26px;
  }

  .article-detail-box p {
    font-size: 18px;
    clear: both;
  }

  .article-detail-box {
    margin-bottom: 50px;
  }

  .article-detail-inner-box {
    padding: 35px 20px;
  }

  .ref-detail {
    font-size: 18px;
  }

  .article-impect {
    margin-bottom: 50px;
  }

  .article-detail-inner-box.on-local {
    margin-bottom: 50px;
  }

  .article-img_overlay {
    display: none
  }

  .banner .owl-nav button {
    display: none !important;
  }
  .banner .slide-image{
    object-fit: fill;
    max-width: 100%;
  }
  .article-info {
    font-size: 10px;
  }

  .news-info {
    font-size: 13px;
  }

  .published {
    font-size: 10px;
  }

  .read-more {
    font-size: 10px;
  }

  .view-more {
    font-size: 13px;
  }

  .common-topic-content .news-info {
    font-size: 12px;
  }

  .research-analysis-content p {
    font-size: 12px;
  }

  .ambassador-content p {
    font-size: 12px;
  }

  .footer-logo {
    margin-bottom: 30px;
  }

  .footer-menu-main {
    margin-bottom: 20px;
  }

  .navbar-brand {
    max-width: 120px;
  }

  .top-menu-nav .navbar-collapse {
    width: 100%;
  }

  .header-social .become-member-btn {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .header-social .footer-social {
    margin-bottom: 30px;
  }

  /* .top-header .search {
       max-width: 100%
   }*/
  .top-header .search .form-control {
    max-width: 100%;
  }

  .sorting .form-control {
    max-width: 115px;
  }

  .middle-footer-left-main {
    order: 2;
    padding-top: 20px;
  }

  .member-sec-main {
    order: 1;
  }

  .podcast-sec .topic-top-info h3 a {
    font-size: 18px;
    padding-bottom: 0px;
  }

  .podcast-sec .news-bottom-article .news-media>ul>li {
    padding-bottom: 5px;
  }

  .podcast-sec .news-media {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .header-social .become-member-btn,
  .header-social .become-member-btn a {
    margin: 0px !important;
    font-size: 11px !important;
    margin-bottom: 10px !important;
  }

  .top-menu-nav .navbar-collapse {
    transform: translateX(-500%);
    width: 300px !important;
    padding-left: 20px !important;
  }

  .top-menu-nav .navbar-collapse.show {
    transform: translateX(7%);
  }

  .top-menu-nav .navbar-collapse .header-social {
    margin: 0px !important;
  }
  .research-analysis {
    padding: 0px 20px;
    background: rgba(204, 204, 204, 0.2509803922);
    margin-top: 40px;
    height: auto;
    padding-bottom: 20px;
}
.common-top-head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 20px;
}
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .community {
    height: 100%;
    min-height: 270px;
  }
  .without-login{
    display: none !important;
  }
  .topup-notification-main{
    display: none;
  }
  .bottom-header {
    display: none;
  }
  .pagination-main{
    position: relative;
    top: 30px;
  }
  .heading-one {
    font-size: 52px;
  }
  .getfullaccess .d-flex{
    display: block !important;
  }
  .box-full-access {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgb(120 120 120 / 41%);
    padding: 30px 50px;
    width: 100%;
    border-radius: 10px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 20px;
  }
  .subscribe-login-in{
    display: block !important;
  }
  .getfullaccess .row button {
    margin-bottom: 0px;
  }
  .edit-ri-line{
    position: relative !important;
    right: 0px !important;
    top: -9px !important;
    border: 1px solid #333;
    padding: 4px 10px 5px 10px;
    border-radius: 20px;
    background: #333;
    display: inline-block;
  }
  // .banner-slider {
  //   padding: 100px 0;
  // }
  .banner .owl-carousel .owl-item{
    min-height: 350px;
    max-height: 350px;
  }
  .banner-slider .heading-one {
    margin-bottom: 25px;
    width: 100%;
    font-size: 30px;
  }
  .latest-news-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    min-height: 350px;
    max-height: 350px;
    background-color: rgba(204, 204, 204, 0.231372549);
  }
  .news-cover {
    margin-top: 40px;
  }
  .common-top-head {
    padding-bottom: 0px;
  }
  .banner-slider p {
    margin-bottom: 25px;
    width: 100%;
  }

  .top-header .container {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .heading-four {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .common-top-head .view-more {
    margin-bottom: 30px;
  }

  .media-img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  .review-main-box {
    margin-left: -20px;
    margin-right: -20px;
  }

  .review-main-box>[class*="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .now-tranding {
    padding: 45px 30px 45px;
  }
  .shop .now-tranding-wrap ul li{
    margin-bottom: 20px;
  }
  .now-tranding .col-md-6 {
    margin-bottom: 30px;
  }

  .analysis-box {
    margin-top: 30px;
  }

  .analysis-box {
    box-shadow: 2px 3px 5px #ccc;
    height: auto;
    margin-top: 30px;
  }

  .now-tranding.videos .col-md-12 {
    margin-bottom: 30px;
  }

  .progress-wrap.active-progress svg {
    position: relative;
    top: -1px;
    left: -2px;
  }

  .common-topics .media-img {
    width: 120px;
    height: 120px;
  }

  .common-topic-content {
    max-width: 65%;
  }

  .common-topics {
    padding: 50px 0 0px;
  }

  .research-analysis-content .published {
    margin-bottom: 5px;
  }

  .research-analysis-content p {
    margin-bottom: 25px;
  }

  .research-analysis-content .article-info {
    margin-bottom: 5.5px;
  }

  .research-analysis-content {
    padding: 27px 15px;
  }

  .ambassador-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ambassador-img {
    margin-bottom: 0;
  }

  .write-us-wrap .topic-top-info {
    margin-bottom: 20px;
  }

  .write-us-btn {
    margin-top: 25px;
  }

  .heading-two {
    font-size: 25px;
  }
  .plan-bg-main ul{
    min-height: 180px;
  }
  .footer-logo-main {
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
  }
  .article-detail-top .heading-five {
    margin-bottom: 15px;
    font-size: 35px;
  }
  .contact-form{
    margin-top: 40px;
  }
  .article-detail {
    padding: 30px 0 0px;
  }
  .plan-bg-main {
    background: #fff;
    border: 1px solid #a8a9ad;
    border-radius: 10px;
    margin-top: 40px;
  }
  .footer-menu-main {
    -ms-flex: 0 0 53%;
    flex: 0 0 53%;
    max-width: 53%;
  }

  .member-sec {
    padding: 20px 25px 25px 25px;
  }

  .news-media>ul>li:last-child {
    margin-bottom: 0;
  }

  .close-menu {
    padding-left: 30px;
    padding-right: 30px;
  }

  .here-from-you .heading-four {
    margin-bottom: 25px;
  }

  .here-from-you p {
    margin-bottom: 20px;
  }

  .here-from-you {
    margin-bottom: 30px;
  }

  .contact-list {
    margin-bottom: 40px;
  }

  .top-menu-nav .navbar-collapse .login-listng {
    padding-left: 30px;
    padding-right: 30px
  }

  .top-menu-nav .navbar-nav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .top-menu-sec {
    min-width: 240px;
  }

  .top-header .search {
    padding: 30px 15px 0;
  }

  .article-detail-top {
    margin-bottom: 40px;
  }

  .article-img {
    margin-bottom: 55px;
  }

  .article-img-detail p {
    margin-bottom: 25px;
  }

  .article-comment-list>ul>li {
    margin-bottom: 50px;
  }

  .box-price {
    font-size: 50px;
  }

  .blog-img {
    height: 455px;
    margin-bottom: 0;
  }

  .blog-content p+p {
    margin-top: 25px;
  }

  .article-blog .heading-two {
    margin-bottom: 40px;
  }

  .article-blog {
    padding: 50px 0 90px;
  }

  ul.article-listing li {
    padding: 0px !important;
  }

  .article-listing-img {
    height: 250px;
    margin-bottom: 0px;
  }

  .article-listing-wrap {
    margin: 0px;
    padding: 0px !important;
    box-shadow: 2px 3px 5px #ccc;
    margin-top: 40px;
  }

  .vacancy-listing-wrap {
    margin: 0px;
    padding: 0px !important;
    box-shadow: 0px 0px 15px 3px #ccc;
    margin-top: 40px;
  }

  .heading-three {
    font-size: 28px;
  }

  .mb-xl-30 {
    margin-bottom: 30px;
  }

  .article-detail-box {
    margin-bottom: 65px;
  }

  .mb-xl-25 {
    margin-bottom: 25px;
  }

  .podcast-sec .news-bottom-article .borr,
  .podcast-sec .news-bottom-article .borb {
    border-right: 0px !important;
    border-bottom: 0px !important;
  }

  .podcast-sec .news-bottom-article .news-media>ul>li {
    border-bottom: 1px solid #8cc4e7;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .common-topic-top-img {
    height: auto;
  }

  .common-topic-content {
    padding-left: 10px;
  }

  .biomedical {
    margin-bottom: 50px;
  }

  body {
    text-align: justify;
  }

  .news-letter-head {
    margin-bottom: 30px;
  }

  .copyright-text {
    margin-bottom: 20px;
  }

  .top-menu-sec {
    position: absolute;
    z-index: 999;
  }

  .navbar-brand {
    margin: 0 auto;
    max-width: 130px;
    margin-right: 50px !important;
  }

  .header-social .become-member-btn,
  .header-social .become-member-btn a {
    margin: 0px !important;
    font-size: 11px !important;
    margin-bottom: 10px !important;
  }

  .top-menu-nav .navbar-collapse {
    width: 350px !important;
    padding-left: 20px !important;
    transform: translateX(-500%);
  }

  .login-listng li a {
    margin-bottom: 0px !important;
  }

  .top-menu-nav .navbar-collapse .header-social {
    margin: 0px !important;
  }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1199px) {
  .bottom-header {
    display: none;
  }

  .bottom-header .navbar .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact-address {
    margin-bottom: 60px;
  }
  .new-class-column-week {
    min-height: 200px !important;
    height: 200px !important;
    max-height: 200px !important;
  }
  .published {
    display: block !important;
  }
  .article-info span {
    display: block;
  }
  .bottom-header {
    display: none;
  }

  .community {
    padding-bottom: 0px !important;
  }
  .tranding-box {
    height: 100%;
    margin-bottom: 30px;
  }
  .analysis-img {
    width: 100%;
    height: 250px;
    min-height: 250px;
    max-height: 350px;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1299px) {
  .navbar-nav li.dropdown>a::after {
    margin-right: 5px !important;
  }
  .heading-four {
    font-size: 32px;
    margin-bottom: 14px;
}
  .heading-one {
    font-size: 80px
  }

  .top-menu-nav .navbar-collapse {
    left: -17px;
    padding-left: 15px;
  }

  .heading-four {
    font-size: 25px;
  }

  .banner-slider .heading-one {
    margin-bottom: 40px;
  }

  .banner-slider p {
    margin-bottom: 30px;
    font-size: 21px;
    width: 60%;
  }

  .navbar-brand {
    max-width: 196px;
  }

  .latest-news {
    padding: 0px 0 100px;
  }

  .review-main-box {
    margin-left: -30px;
    margin-right: -30px;
  }

  .review-main-box>[class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .review-main-box>li {
    margin-bottom: 65px;
  }

  .now-tranding {
    padding: 50px 30px 50px;
  }

  .now-tranding-wrap>ul>li {
    margin-bottom: 30px;
  }

  .topic-top-info p {
    font-size: 20px;
  }

  .write-us-wrap .topic-top-info p {
    font-size: 32px;
  }

  .research-analysis-content .topic-top-info p {
    font-size: 16px;
  }

  .latest-news.opinion-sec {
    padding: 50px 0 50px;
  }

  .ambassador-content .font-14 {
    margin-bottom: 20px;
  }

  .ambassador {
    padding: 50px 40px;
  }

  .news-bottom-article {
    margin-bottom: 0px;
  }

  .common-topic-box {
    margin-bottom: 0;
  }

  .research-analysis-main {
    margin-bottom: 0;
  }

  .ambassador-btn {
    margin-top: 35px;
  }

  .ambassador-content {
    padding-left: 25px;
    padding-right: 25px;
  }

  .common-topics.podcast-sec {
    padding: 50px 0 50px;
  }

  .tranding-box-content {
    padding: 15px 20px;
  }

  .comments-list>ul>li {
    padding-bottom: 15px;
    padding-top: 20px;
    margin-bottom: 0px !important;
  }

  .comments-list>ul>li:first-child {
    padding-top: 0px;
  }

  .common-topics.vault {
    padding: 50px 0 50px;
  }

  .events .latest-news-img {
    height: 340px;
  }

  .latest-news.events {
    padding: 50px 0 50px
  }

  .shop .tranding-box-content {
    padding: 15px;
  }

  .shop {
    padding: 45px 30px 55px 30px;
  }

  .shop .now-tranding-wrap>ul>li {
    margin-bottom: 0px !important;
  }

  .news-letter {
    padding: 10px 0 40px;
  }

  .news-letter-wrap {
    padding: 50px 40px;
  }

  .heading-two {
    font-size: 30px;
  }

  .footer-menu li {
    margin-bottom: 15px;
  }

  .member-sec-main {
    -ms-flex: 0 0 38%;
    flex: 0 0 38%;
    max-width: 38%;
    float: left;
  }

  .middle-footer-left-main {
    -ms-flex: 0 0 62%;
    flex: 0 0 62%;
    max-width: 62%;
    float: right;
  }

  .middle-footer-left {
    padding: 20px 0;
    margin-bottom: 0;
  }

  .navbar-nav {
    font-size: 13px;
  }

  .search {
    max-width: 180px;
  }

  .contact-form label {
    font-size: 20px
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 18px;
  }

  .contact-form .heading-two {
    margin-bottom: 55px;
  }

  .contact-form .form-group {
    margin-bottom: 40px;
  }

  .contact-address>.heading-four {
    max-width: 370px;
  }

  .contact-main {
    padding: 105px 0 35px;
  }

  .contact-form {
    padding-left: 90px;
  }

  .close-menu {
    margin-top: 70px;
  }

  .pl-30px {
    padding-left: 30px;
  }

  .bottom-header .navbar-nav .nav-item {
    margin-right: 5px;
  }

  .bottom-header .navbar-nav .nav-link {
    padding: 25px 0;
  }

  .article-detail {
    padding: 60px 0 15px;
  }

  .heading-five {
    font-size: 20px
  }

  .article-img-detail p {
    margin-bottom: 30px;
  }

  .article-img-detail {
    margin-bottom: 40px;
  }

  .article-comment {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .article-comment-list>ul>li {
    margin-bottom: 70px;
  }

  .bottom-header .navbar .dropdown-menu {
    margin: 0;
    min-width: 270px;
    background-color: #f2f2f2;
    border-radius: 0 !important;
    left: -25px;
    padding: 15px 15px 20px;
  }

  .bottom-header .navbar .dropdown-menu::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #f2f2f2;
    position: absolute;
    top: -15px;
    left: 35px;
  }

  .bottom-header .navbar .dropdown-menu a {
    font-size: 15px;
  }

  .pricing-listing li {
    margin-bottom: 25px;
  }

  .pricing-listing {
    margin-bottom: 55px;
  }

  .pricing-top-content {
    padding: 70px 0;
  }

  .pricing-top-content p {
    font-size: 20px;
  }

  .pricing-box p {
    font-size: 20px;
  }

  .box-price {
    font-size: 60px;
    margin-bottom: 5px;
  }

  .pricing-standard {
    margin-bottom: 15px;
  }

  .pricing-box-wrap {
    margin-bottom: 30px;
  }

  .blog-img {
    height: 435px;
  }

  .article-blog {
    padding: 50px 0 120px;
  }

  /*  .article-listing.row {
  margin-left: -20px;
  margin-right: -20px;
  }
  .article-listing.row>[class*="col-"]{
  padding-left: 20px;
  padding-right: 20px;
  }*/
  /*  .article-listing {
  margin-left: 10px;
  }*/

  .shop.shop-main .common-top-head .heading-two {
    margin-bottom: 30px;
  }

  .shop.shop-main .common-top-head .sorting {
    margin-bottom: 30px;
  }

  .shop.shop-main .now-tranding-wrap>ul>li {
    margin-bottom: 35px !important;
  }

  .article-img {
    margin-bottom: 70px;
  }

  .heading-three {
    font-size: 30px;
  }

  .article-detail-box p {
    font-size: 20px;
    clear: both;
  }

  .article-detail-box {
    margin-bottom: 80px;
  }

  .article-detail-inner-box {
    padding: 35px 30px;
  }

  .ref-detail {
    font-size: 20px;
  }

  .reference-list li {
    margin-bottom: 25px;
  }

  .article-detail-box.analysis-report {
    margin-bottom: 50px;
  }

  .article-impect {
    margin-bottom: 60px;
  }

  .article-detail-inner-box.on-local {
    margin-bottom: 60px;
  }

  .community {
    padding-bottom: 0px !important;

  }

  .community-wrap h2 {
    margin-bottom: 15px;
    font-size: 28px;
  }

}

@media screen and (min-device-width: 1300px) and (max-device-width: 1399px) {
  .container {
    max-width: 1336px;
  }

  .search {
    max-width: 180px;
  }
  .bottom-header .navbar-nav .nav-link {
    padding: 25px 0px;
    font-size: 13px;
  }
  .navbar-nav {
    font-size: 15px;
  }

  .header-social .become-member-btn {
    margin-right: 18%;
  }

  .pricing-top-box {
    padding: 35px 30px;
  }

  .pricing-bottom-box {
    padding: 40px 30px;
  }

  .blog-img {
    height: 410px;
  }

  section.article-detail .article-img_overlay {
    right: 0px !important
  }

  .heading-four {
    font-size: 24px;
  }

  /*  .article-listing.row {
  margin-left: -30px;
  margin-right: -30px;
  }
  .article-listing.row>[class*="col-"]{
  padding-left: 30px;
  padding-right: 30px;
  }*/

  // .article-listing-img {
  //   height: 400px;
  // }

  .community {
    padding-bottom: 0px !important;
  }

  .navbar-nav li.dropdown>a::after {
    margin-right: 15px !important;
  }

  .article-list-content .article-tag a {
    padding: 5px 8px;
    font-size: 12px;
  }
}

@media screen and (min-device-width: 1400px) and (max-device-width: 1499px) {
  .header-social .become-member-btn a {
    margin: 0px !important;
    font-size: 12px !important;
  }
}

@media screen and (min-device-width: 1300px) and (max-device-width: 2299px) {
  .container {
    max-width: 1336px;
  }

  .top-menu-sec {
    min-width: 240px;
  }
}
.filter-article label {
  font-weight: bold;
  text-transform: uppercase;
}

.scroll-mt-45{
  scroll-margin-top: 45vh;
}

.scroll-mt-40{
  scroll-margin-top: 40vh;
}

iframe {
  width: 100%;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
